<template>
  <div class="user-comparison">
    <b-card>
      <b-card-title>Сравнение пользователей с Excel файлом</b-card-title>
      
      <b-card-body>
        <b-form @submit.prevent="handleSubmit">
          <b-form-group label="Загрузите Excel файл">
            <b-form-file
              v-model="file"
              accept=".xlsx,.xls"
              :state="Boolean(file)"
              placeholder="Выберите файл или перетащите его сюда"
              drop-placeholder="Перетащите файл сюда"
            />
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="!file || isUploading"
          >
            {{ isUploading ? 'Загрузка...' : 'Сравнить' }}
          </b-button>
        </b-form>

        <div v-if="error" class="mt-3">
          <b-alert show variant="danger">{{ error }}</b-alert>
        </div>

        <div v-if="matches.length > 0" class="mt-4">
          <h4>Результаты сравнения ({{ totalMatches }} совпадений)</h4>
          <b-table
            :items="matches"
            :fields="fields"
            striped
            hover
            responsive
          >
            <template #cell(user_full_name)="data">
              <router-link :to="{ name: 'user-view', params: { id: data.item.user_id }}">
                {{ data.item.user_full_name }}
              </router-link>
            </template>
          </b-table>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UserComparison',
  data() {
    return {
      file: null,
      isUploading: false,
      error: null,
      matches: [],
      totalMatches: 0,
      fields: [
        { key: 'excel_name', label: 'Имя из Excel' },
        { key: 'user_full_name', label: 'Пользователь в системе' },
      ],
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.file) return

      this.isUploading = true
      this.error = null
      this.matches = []
      this.totalMatches = 0

      const formData = new FormData()
      formData.append('file', this.file)

      try {
        const response = await this.$http.post('/admin/compare-users-excel/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.matches = response.data.matches
        this.totalMatches = response.data.total_matches
      } catch (error) {
        this.error = error.response?.data?.error || 'Произошла ошибка при загрузке файла'
      } finally {
        this.isUploading = false
      }
    },
  },
}
</script>

<style scoped>
.user-comparison {
  padding: 1rem;
}
</style> 